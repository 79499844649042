"use client";

import React, { useId, useRef, useState } from "react";
import cn from "@/utils/cn";
import useIsInteractive from "@/hooks/useIsInteractive";

const defaultBreakpoints = {
  768: {
    slidesPerView: 2,
    spaceBetween: 16,
  },
  1024: {
    slidesPerView: 3,
  },
};
const defaultSpaceBetween = 20;
const defaultSlidesPerView = 1;
const defaultAutoHeight = false;
const defaultPagination = { clickable: true };

const Swiper = ({
  children,
  breakpoints = defaultBreakpoints,
  spaceBetween = defaultSpaceBetween,
  slidesPerView = defaultSlidesPerView,
  autoHeight = defaultAutoHeight,
  className = "",
  pagination = defaultPagination,
  modules = [],
  ...rest
}) => {
  const [Component, setComponent] = useState(null);
  const showSwiper = useRef(false);
  const id = "swiper-" + useId().replace(/:/g, "");
  const _breakpoints = { 0: { slidesPerView: 1 }, ...breakpoints };

  const initialColsAndGap = (spaceBetween, slidesPerView) => {
    return `
          --gap: ${spaceBetween}px;
          --cols: repeat(${slidesPerView}, 1fr); 
      `;
  };

  const hideChildren = (slidesPerView) => {
    const header = Array.from({ length: slidesPerView })
      .map((_, index) => `.${id} .swiper-child-${id}-${index}`)
      .join(", ");

    return `${header} { display: block }`;
  };

  const _className = Object.entries(_breakpoints)
    .map(([key, value]) => {
      const { slidesPerView = 1, spaceBetween: gap } = value;
      return `
         @media (min-width: ${key}px) {
            .${id} { 
               ${initialColsAndGap(gap || spaceBetween, slidesPerView)}
            }
            
            ${hideChildren(slidesPerView)}
          }`;
    })
    .join(" ")
    .replace(/\s+/g, " ");

  useIsInteractive(async () => {
    if (showSwiper.current) return;

    const { default: BaseSwiper } = await import("@/components/Swiper/BaseSwiper");
    showSwiper.current = true;

    setComponent(
      <BaseSwiper
        breakpoints={_breakpoints}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        autoHeight={autoHeight}
        className={className}
        pagination={pagination}
        observer
        {...rest}
      >
        {children}
      </BaseSwiper>
    );
  });

  if (Component) return Component;

  return (
    <>
      <style>{_className}</style>
      <div
        className={cn(
          `grid grid-cols-[var(--cols)] gap-[var(--gap)] overflow-clip ${id}`,
          className
        )}
      >
        {React.Children.map(children, (child, index) => (
          <div className={`hidden swiper-child-${id}-${index}`}>{child}</div>
        ))}
      </div>
    </>
  );
};

export default Swiper;
